var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "tingli-play" },
    [
      _c("heads"),
      _c("div", { staticClass: "con" }, [
        _c(
          "div",
          {
            staticClass: "goBack",
            on: {
              click: function($event) {
                return _vm.goBack()
              }
            }
          },
          [
            _c("i", { staticClass: "el-icon-arrow-left back-icon" }),
            _vm._v("返回列表")
          ]
        ),
        _c("div", { staticClass: "title" }, [
          _vm._v(_vm._s(_vm.currentData.name))
        ]),
        _c("div", { staticClass: "play-con" }, [
          _c("img", {
            staticClass: "video-img",
            attrs: { src: _vm.videoimg, alt: "" }
          }),
          _c("img", {
            staticClass: "stop",
            attrs: { src: _vm.isPlay ? _vm.playing : _vm.stop, alt: "" },
            on: {
              click: function($event) {
                return _vm.startOrStop()
              }
            }
          })
        ]),
        _c(
          "div",
          { staticClass: "t-bottom" },
          [
            _c("span", { staticClass: "already-time" }, [
              _vm._v(_vm._s(_vm.startTime))
            ]),
            _c("el-slider", {
              staticClass: "jindu",
              attrs: { "show-tooltip": false, max: _vm.timeCount },
              on: { input: _vm.proChange, change: _vm.proUp },
              model: {
                value: _vm.progress,
                callback: function($$v) {
                  _vm.progress = $$v
                },
                expression: "progress"
              }
            }),
            _c("span", { staticClass: "total-time" }, [
              _vm._v(_vm._s(_vm.endTime))
            ])
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "sudu", attrs: { id: "sudu" } },
          [
            _c("div", { staticClass: "sudu-title" }, [_vm._v("播放速度：")]),
            _vm._l(_vm.speedList, function(item, key) {
              return _c(
                "div",
                {
                  key: key,
                  class: "sudu-list " + (_vm.speed === item ? "l-name-bf" : ""),
                  on: {
                    click: function($event) {
                      return _vm.speedClick(item)
                    }
                  }
                },
                [_vm._v("\n        " + _vm._s(item) + "x\n      ")]
              )
            })
          ],
          2
        ),
        _c(
          "div",
          {
            staticClass: "down-tingli",
            on: {
              click: function($event) {
                return _vm.download(_vm.currentData)
              }
            }
          },
          [_vm._v("下载")]
        )
      ]),
      _c("audio", {
        attrs: {
          id: "audioFile",
          src: _vm.currentData.url,
          controls: "",
          autoplay: ""
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }