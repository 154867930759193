<template>
  <!-- 播放 -->
  <div class="tingli-play">
    <heads />
    <div class="con">
      <div class="goBack" @click="goBack()"><i class="el-icon-arrow-left back-icon" />返回列表</div>
      <div class="title">{{ currentData.name }}</div>
      <div class="play-con">
        <img :src="videoimg" class="video-img" alt="" />
        <img :src="isPlay ? playing : stop" class="stop" alt="" @click="startOrStop()" />
      </div>
      <div class="t-bottom">
        <span class="already-time">{{ startTime }}</span>
        <el-slider
          v-model="progress"
          :show-tooltip="false"
          :max="timeCount"
          class="jindu"
          @input="proChange"
          @change="proUp"
        />
        <span class="total-time">{{ endTime }}</span>
      </div>
      <div id="sudu" class="sudu">
        <div class="sudu-title">播放速度：</div>
        <div
          v-for="(item, key) in speedList"
          :class="`sudu-list ${speed === item ? 'l-name-bf' : ''}`"
          :key="key"
          @click="speedClick(item)"
        >
          {{ item }}x
        </div>
      </div>
      <div class="down-tingli" @click="download(currentData)">下载</div>
    </div>
    <!-- 播放音频 -->
    <audio id="audioFile" :src="currentData.url" controls autoplay />
  </div>
</template>
<script>
import _ from 'lodash';
import heads from './common/head.vue';
import videoimg from '../assets/play/videoimg.png';
import playing from '../assets/play/playing.png';
import stop from '../assets/play/stop.png';

export default {
  components: {
    heads,
  },
  data() {
    const { url = '', name = '' } = this.$route.query || {};
    return {
      videoimg,
      stop,
      playing,
      // 播放进度
      progress: 0,
      // 当前正在播放的音频
      currentData: {
        url: decodeURIComponent(url),
        name,
      },
      // 总时间
      timeCount: 0,
      // 已经播放时间格式和总时长格式
      startTime: '00:00',
      endTime: '00:00',
      // 是否正在播放
      isPlay: false,
      // 鼠标是否按下
      isDown: false,
      speedList: [1.0, 1.25, 1.75, 2.0],
      speed: 1.0,
    };
  },
  mounted() {
    const vid = document.getElementById('audioFile');
    // 结束
    vid.addEventListener('ended', this.overPlay);
    // 监听鼠标按下时间
    window.addEventListener('mousedown', this.mousedown);
    // 鼠标抬起
    window.addEventListener('mouseup', () => {
      this.isDown = false;
    });
    if (this.currentData.url) {
      this.bofang();
    }
  },
  created() {},
  methods: {
    /* 播放 */
    bofang() {
      const audio = document.getElementById('audioFile');
      audio.pause();
      const total = setInterval(() => {
        if (audio.duration) {
          this.timeCount = _.parseInt(audio.duration);
          this.endTime = this.secondToTime(audio.duration);
          clearInterval(total);
        }
      }, 100);
    },
    /* 播放或者暂停 */
    startOrStop() {
      if (!this.currentData.url) {
        return false;
      }
      this.isPlay = !this.isPlay;
      const audio = document.getElementById('audioFile');
      if (this.isPlay) {
        // 播放
        audio.play();
        this.time = setInterval(() => {
          this.progress = audio.currentTime ? _.parseInt(audio.currentTime) : 0;
        }, 1000);
      } else {
        // 暂停
        audio.pause();
        clearInterval(this.time);
      }
    },
    /* 播放完毕 */
    overPlay() {
      clearInterval(this.time);
      this.progress = this.timeCount;
      this.isPlay = false;
    },
    // 播放速度
    speedClick(speed) {
      this.speed = speed;
      const audio = document.getElementById('audioFile');
      audio.playbackRate = speed;
      audio.play();
    },
    /* 拖拽播放 */
    proChange() {
      // 计算播放时长
      if (this.isDown) {
        clearInterval(this.time);
        const audio = document.getElementById('audioFile');
        audio.pause();
      }
      this.startTime = this.secondToTime(this.progress);
    },
    /* 拖拽结束(鼠标抬起) */
    proUp() {
      this.isDown = false;
      const audio = document.getElementById('audioFile');
      audio.currentTime = this.progress;
      if (this.isPlay) {
        audio.play();
      }
      this.time = setInterval(() => {
        this.progress = audio.currentTime ? _.parseInt(audio.currentTime) : 0;
      }, 1000);
    },
    /* 鼠标按下时间 */
    mousedown() {
      this.isDown = true;
    },
    /* 下载 */
    download(val) {
      const request = new XMLHttpRequest();
      request.responseType = 'blob';
      request.open('GET', val.url);
      request.onload = () => {
        const url = window.URL.createObjectURL(request.response);
        const a = document.createElement('a');
        document.body.appendChild(a);
        a.href = url;
        a.download = val.name;
        a.click();
      };
      request.send();
    },
    /* 把秒转化为时间格式 */
    secondToTime(val) {
      const minute = val / 60;
      let minutes = _.parseInt(minute);
      if (minutes < 10) {
        minutes = `0${minutes}`;
      }
      // 秒
      const second = val % 60;
      let seconds = Math.round(second);
      if (seconds < 10) {
        seconds = `0${seconds}`;
      }
      return `${minutes}:${seconds}`;
    },
    /* 返回 */
    goBack() {
      this.$router.push({
        path: '/hearing',
      });
    },
  },
};
</script>
<style lang="less">
.tingli-play {
  .el-slider__runway {
    height: 2px;
    width: 440px;
    margin: 0px;
  }
  .el-slider__bar {
    height: 2px;
  }
  .el-slider__button-wrapper {
    width: 12px;
    height: 12px;
    top: -9px;
  }
  .el-slider__button {
    width: 12px;
    height: 12px;
    border: 0px solid #409eff;
    background-color: #1890ff;
  }
}
</style>
<style lang="less" scoped>
.tingli-play {
  width: 100%;
  padding-top: 160px;
  height: calc(100% - 160px);
  background: #eff4f8;
  position: relative;
  #audioFile {
    display: none;
  }
  .con {
    position: relative;
    width: 1200px;
    height: 100%;
    margin: auto;
    background: #fff;
  }
  .goBack {
    position: absolute;
    width: 108px;
    height: 38px;
    opacity: 1;
    background: #ffffff;
    border: 1px solid #d9d9d9;
    font-size: 14px;
    color: #1890ff;
    top: 40px;
    left: 40px;
    line-height: 38px;
    text-align: center;
    cursor: pointer;
  }
  .back-icon {
    margin-right: 6px;
  }
  .title {
    font-size: 18px;
    font-family: PingFangSC, PingFangSC-Medium;
    font-weight: 500;
    color: #000000;
    text-align: center;
    padding-top: 73px;
  }
  .play-con {
    width: 400px;
    height: 400px;
    margin: auto;
    margin-top: 60px;
    position: relative;
  }
  .video-img {
    display: block;
    width: 400px;
    height: 400px;
    position: absolute;
    left: 0px;
    top: 0px;
  }
  .stop {
    display: block;
    width: 36px;
    height: 36px;
    position: absolute;
    left: 182px;
    top: 182px;
    cursor: pointer;
  }
  .t-bottom {
    width: 584px;
    height: 20px;
    margin: auto;
    margin-top: 56px;
    display: flex;
    justify-content: space-between;
  }
  .jindu {
    height: 2px;
    width: 440px;
  }
  .already-time {
    height: 17px;
    font-size: 18px;
    color: #adadad;
    line-height: 17px;
    margin-top: -6px;
  }
  .total-time {
    font-size: 18px;
    color: #adadad;
    height: 17px;
    line-height: 17px;
    margin-top: -6px;
  }
  .down-tingli {
    width: 100px;
    height: 40px;
    opacity: 1;
    background: #1890ff;
    border-radius: 2px;
    line-height: 40px;
    text-align: center;
    cursor: pointer;
    font-size: 14px;
    color: #fff;
    margin: auto;
    margin-top: 50px;
  }
  .sudu {
    width: 260px;
    display: flex;
    // justify-content: space-between;
    align-items: center;
    margin: auto;

    .sudu-title {
      width: 90px;
    }
    .sudu-list {
      cursor: pointer;
      width: auto;
      text-align: center;
      margin-right: 16px;
    }
    .l-name-bf {
      color: #1890ff;
    }
  }
}
</style>
